export default {
    // 模板数据
    formData: null,
    // 对应的模板类型下的模板列表，如采购单下的采购单列表
    formTplList: [],

    printer: null,
    initData: null,
    selectFileData: [],
    dragbox: [],
    paper: {},
    tplId: 0,
    // lodop打印机
    printerList: [],
    currentPrinter: '',
    // 菜鸟打印机
    printerListCainiao: [],
    currentPrinterCainiao: '',
    //菜鸟账户列表
    cainiaoAccountList: [],
    // 根据菜鸟账户生成的站点列表
    cainiaoSiteList: [],
    // 选择的账户
    selectCainiaoAccount: '',
    // 选择的站点
    selectCainiaoSite: '',
    // 菜鸟发件人姓名
    cainiaoSender: '',
    cainiaoSendPhone: '',
    // 设为默认
    cainiaoSetDefault: true,
    cainiaoAccountFlag: false,
    // 
    currentTable: {},
    printSuccess: false,
    selectPrinterFlag1: false,
    selectPrinterFlag2: false,
    selectPrinterFlag3: false,
    loading1: null,
    loading2: null,
    text1: '',
    text2: '',
    textStyle: {},
    boxStyle: {},
    installLodop: false,
    printCopies: 1,
    //选中的模版
    currentTemplate: '',
    config: {
        isA: false,
        tuikuan: true,
        close: false,
        repeat: true
    },
    // 系统变量字段，和设计模版时的保持一致,打印不同单据时字段不一样
    fieldList: [],
    //模版id，当模式为菜鸟和普通时有不同含义
    templateId: '',
    cainiaoTemplateDetail: {},
    // 模版类型
    ptype: 0,
    // 订单总长度
    orderLength: 0,
    // 已打印快递单总长度
    sendOrderLength: 0
}