import apis from "../../common/js/api2";
import tools from '../../../extends/tools.js';
import Printer from './PrintOrder';
export default {
    async init() {
        if (!this.form_id) {
            this.$message({
                type: 'error',
                message: '没有指定单据id'
            })
            return;
        }
        if (!this.tplType) {
            this.$message({
                type: 'error',
                message: '请指定单据类型'
            })
            return;
        }
        //获取打印列表
        this.selectPrinter();

        //获取对应的数据源字段,不同的单据字段不一样，需要进行转换
        apis.getDynamicField({
            type: this.typeId
        }).then((res) => {
            if (tools.msg(res)) {
                this.fieldList = {};
                //资金字段单据转化
                if (res.data.details !== undefined) {
                    res.data.goods = res.data.details;
                }

                let field = res.data.goods;
                for (let i in field) {
                    this.fieldList[i.split('.')[1]] = field[i];
                }
            }
        }).catch((error) => {
            tools.err(error, this);
        });
        // 获取打印配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.printConfig = res.data.basic;
            }
        }).catch(err => {
            tools.err(err, this);
        });

        // 获取单据信息
        let method = this.getFormDetail();
        apis[method]({
            id: this.form_id
        }).then(res => {
            if (tools.msg(res, this)) {
                this.formData = res.data;
                //当单据为资金单据时，把详情字段转换一下
                if (this.formData.details !== undefined) {
                    this.formData.goods_info = this.formData.details;
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        // 获取模板信息
        apis.getPirntTplByType({
            type: this.typeId
        }).then(res => {
            this.formTplList = res.data;
        }).catch(err => {
            tools.err(err, this);
        });
    },

    getFormDetail() {
        /**
         * [
                {
                    "id": 1,
                    "name": "发货单"
                },
                {
                    "id": 2,
                    "name": "备货单"
                },
                {
                    "id": 3,
                    "name": "普通快递单"
                },
                {
                    "id": 4,
                    "name": "采购单"
                },
                {
                    "id": 5,
                    "name": "采购退货单"
                },
                {
                    "id": 6,
                    "name": "销售单"
                },
                {
                    "id": 7,
                    "name": "销售退货单"
                },
                {
                    "id": 8,
                    "name": "盘点"
                },
                {
                    "id": 9,
                    "name": "调拨"
                },
                {
                    "id": 10,
                    "name": "借出"
                },
                {
                    "id": 11,
                    "name": "借入"
                },
                {
                    "id": 12,
                    "name": "收款单"
                },
                {
                    "id": 13,
                    "name": "付款单"
                },
                {
                    "id": 16,
                    "name": "出库单"
                },
                {
                    "id": 17,
                    "name": "入库单"
                }
                ]
                    * 
         */
        let methodObj = {
            '4': 'purchaseDetail',
            '5': 'purchaseTuiDetail',
            '6': 'saleDetail',
            '7': 'sale_tuiDetail',
            '8': 'inventoryDetail',
            '9': 'allotDetail',
            '10': 'lendDetail',
            '11': 'borrowDetail',
            '12': 'collectionDetail',
            '13': 'paymentDetail',
            '16': 'warehouseOutDetail',
            '17': 'warehouseInDetail'
        };

        return methodObj[this.typeId];
    },
    printOrder(mode) {
        // 初始化打印对象
        this.printer = new Printer.Printer(this, this);
        this.printer.printOrder(mode);
    },
    // 快递模版
    manageExpressTpl() {
        this.$router.push({
            path: '/print'
        });
    },
    exit() {
        this.$emit('printLodopExit');
    },
    back() {
        this.$router.push({
            path: '/print'
        })
    },
    getTplById(id) {
        let tpl = null;
        this.templateList_normal.forEach((item, key) => {
            item.children.forEach((i, k) => {
                if (i.value === id) {
                    tpl = i;
                }
            });
        });
        return tpl;
    },
    selectPrinter() {
        //配置打印机
        let count = this.LODOP.GET_PRINTER_COUNT();
        if (count == 0) {
            this.$alert('无可用打印机', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        let arr = [];
        for (let i = 0; i < count; i++) {
            arr.push(this.LODOP.GET_PRINTER_NAME(i));
        }
        this.printerList = arr;
    },

}